@import '../../styles/variables';
@import '../../styles/mixins';


.sideModalOverlay  {
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
}
.sideModal {
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    height: 100vh;
    max-height: 100vh;
    max-width: 700px;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 20px;
    width: calc(100vw - 20px);
    z-index: 101;
    animation: openModal .3s cubic-bezier(0, .52, 0, 1);
    &Inner {
        padding: 20px;
    }
}


@keyframes openModal {
    0% { transform: translate3d(100vw, 0, 0); }
    100% { transform: translate3d(0vw, 0, 0); }
}


@include breakpoint-up(sm) {
    .sideModal {
        left: initial;
        right: 0;
    }

}


.modalCloseButton {
    padding: 0;
    border: none;
    right:15px;
    position: absolute;
    cursor: pointer;
    top: 15px;
    z-index: 4;
}





