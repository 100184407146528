a[disabled] {
    pointer-events: none;
}

.link-reverse {
    color: $body-color;
    &:hover {
        color: $primary;
    }
}
.link-underline {
    text-decoration: underline;
}
.link-primary {
    color: $primary;
}
button {
    appearance: none;
    padding: 0;
    border: none;
    cursor: pointer;
}

.btn {
    background: $primary;
    border: 1px solid $primary;
    color: #fff;;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    padding: .4rem 2rem;
    vertical-align: middle;
    user-select: none;
    transition: all 0.2s ease-in;
    border-radius: 1rem;

    &:focus {
      outline: none;
    }

    &:hover {
        background: darken($primary, 10%);
    }

    &-muted {
        color: $body-color;
        background: #EEF1F3;
        border-color: #EEF1F3;
        &:hover {
            background: darken(#EEF1F3, 10%);
            border-color: darken(#EEF1F3, 10%);
        }
    }
    &-blue {
        color: #fff;
        background: $blue;
        border-color: $blue;

        &:hover {
            background: darken($blue, 10%);
            border-color: darken($blue, 10%);
        }
    }
    &-outline {
        background: #fff;
        color: $body-color;
        border: 2px solid $primary;
        &:hover {
            background: #fff;
            color: $primary;
            border-color: $primary;
        }
    }
    &-danger {
        background: $danger;
        border-color: $danger;
    }

    &:disabled {
        background: #ABABAB;
        color: #979797;
        pointer-events: none;
        border-color: #ABABAB;
        color: #4A4A4A;
    }
}

.btn-small {
    font-size: 1.4rem!important;
    padding: .3rem 1rem!important;
}
