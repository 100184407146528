@import '../../styles/mixins';
@import '../../styles/variables';

.item {
    display: flex;
    width: 100%;
    text-align: left;
    &Date {
        flex: 0 0 10rem;
        margin-right: 1rem;
    }
    &Main {
        flex-grow: 1;
    }
    &Side {
        margin-left: auto;
    }
}

.refDetails {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap:3rem;
}

.buttonContainer {
    display: inline-block;
}

.inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &+label {
        background: $primary;
        border-radius: 9px;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        line-height: normal;
        padding: 6px 20px;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            background: lighten($primary, 5%);
        }
    }

    &:focus+label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
}

.buttonIcon {
    margin-right: .5rem;
    position: relative;
    top: .3rem;
}