@each $point in map-keys($grid-breakpoints) {
  $infix: get-infix($point, $grid-breakpoints);
  @include breakpoint-up($point, $grid-breakpoints) {
    @each $prop, $abbr in $space-properties {
      @each $size, $len in $spacers {
        .#{$abbr}t#{$infix}-#{$size},
        .#{$abbr}y#{$infix}-#{$size} {
          #{$prop}-top: $len !important;
        }

        .#{$abbr}b#{$infix}-#{$size},
        .#{$abbr}y#{$infix}-#{$size} {
          #{$prop}-bottom: $len !important;
        }
        .#{$abbr}#{$infix}-#{$size} {
            #{$prop}: $len !important;
        }
        .#{$abbr}l#{$infix}-#{$size} {
            #{$prop}-left: $len !important;
        }
        .#{$abbr}r#{$infix}-#{$size} {
            #{$prop}-right: $len !important;
        }
        .px#{$infix}-#{$size} {
          padding-right: $len !important;
          padding-left: $len !important;
        }
        .w#{$infix}-#{$size} {
          width: $len !important;
        }

        .h#{$infix}-#{$size} {
          height: $len !important;
        }
      }
    }

    .m#{$infix}-auto { margin: auto !important; }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}

.u-m-top-20 {
  margin-top: 20px;
}

.u-m-base-20 {
  margin-bottom: 20px;
}

.u-m-top-10 {
  margin-top: 10px;
}

.u-m-right-5 {
  margin-right: 5px;
}

.u-base-tight {
  margin-bottom: 0;
}

.u-m-base-5 {
  margin-bottom: 5px;
}

.u-m-right-10 {
  margin-right: 10px;
}
.u-m-right-20 {
  margin-right: 20px;
}
.u-m-top-40 {
  margin-top: 40px;
}