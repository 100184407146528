.label {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.2rem;
    border-radius: 10rem;
    padding: .3rem 1.2rem;
    min-width: 4rem;
    text-align: center;
    white-space: nowrap;
    font-weight: 700;
    &-primary {
        background: $primary;
        color: #fff;
    }
    &-primary-light {
        background: lighten($primary, 10%);
    }
    &-blue {
        background: $blue;
        color: #fff;
    }
    &-yellow {
        background: #D8A027;
        color: #fff;
    }
    &-green {
        background: #9EA956;
        color: #fff;
    }
    &-muted {
        color: #fff;
        background: #B4B4B4;
    }
    &-danger {
        color: #fff;
        background: $danger;
    }
}