@import '../../styles/mixins';
@import '../../styles/variables';

.header {
    display: flex;
    justify-content: space-between;
    > div {
        flex-basis: 25rem;
    }
}

.nav {
    display: flex;
    &Link {
        background: #fff;
        border-radius: 1rem;
        text-align: center;
        min-width: 10rem;
        height: 11rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 .5rem;
        color: $body-color;
        border: 2px solid transparent;
        &Active {
            border: 2px solid #E89B89;
        }
    }
    &Block {
        background: none;
    }
}
.noPractitionerLevel {
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.itemsWrapper {
    display: flex;
    padding: 0 1rem;
    > div {
        margin-right: 1px;
    }
}
.itemLevel {
       width: 85px;
       height: 66px;
       color: #fff;
       text-align: center;
       padding: 0.5rem;
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       background: #B4B4B4;
   }

.log {
    border-bottom: 1px solid #B4B4B4;
}
.logDuration {
    background: #666666;
    color: #fff;
    padding: 1rem;
    margin-left: 1rem;
    flex-basis: 12rem;
    max-width: 12rem;
    text-align: center;
}

.entries {
    flex: 0 0 80px;
    margin-right: 1rem;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.2;
    &Title {
        font-size: 2rem;
        font-weight: bold;
    }
}


/* Training requirements */
.reqHeader {
    display: flex;
    margin-right: 6rem;
    margin-bottom: 1rem;
    justify-content: flex-end;
    &El {
        display: flex;
        font-weight: bold;
        flex: 0 0 8.5rem;
        justify-content: center;
    }
}
.reqItem {
    display: flex;
    background: $primary-light;
    margin-bottom: .3rem;
    min-height: 6.6rem;
    &Label {
        flex: 0 0 4rem;
        display: flex;
        justify-content: center;
        color: #fff;
        padding-top: 1rem;
    }
    &Name {
        padding: 1rem;
    }
    &Side {
        margin-left: auto;
        display: flex;
    }
    &Levels {
        display: flex;
        justify-content: flex-end;
        &El {
            display: flex;
            font-weight: bold;
            align-items: center;
            justify-content: center;
            color: #fff;
            flex: 0 0 8.5rem;
            border-right: 2px solid #fff;
            background: lighten($primary, 10%);
        }
    }
    &Edit {
        width: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &Value {
        background: $primary;
    }
}

.eqCourses {
    display: grid;
    grid-template-columns: 1fr 1fr 240px;
    flex-direction: row;
}