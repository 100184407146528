@import '../../styles/variables';
@import '../../styles/mixins';

.user {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    border-bottom: 1px solid #F3CCBD;
    &Photo {
        background-size: contain;
        background-position: center;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;
    }
    &NoPhoto {
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;
        background: rgb(126, 126, 126) url('../../images/icons/user.png') no-repeat center center;
        background-size: contain;

    }
    &Main {
        flex: 1;
        display: flex;
        align-items: center;
        a {
            color: $body-color;
        }
    }
    &Title {
        font-size: 1.8rem;
        margin-bottom: 0;
        line-height: 1;
        display: flex;
    }
    &Actions {
        margin-left: auto;
    }
}