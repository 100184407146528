@import '../../styles/mixins';
@import '../../styles/variables';


.chartArea {
    margin: 5rem auto;
}

.personaList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &Item {
        flex-basis: 9rem;
        max-width: 9rem;
        margin: 1rem 3rem 2rem 3rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &Title {
        font-weight: 400;
    }
    &Img {
        border-radius: 3.5rem;
        width: 7rem;
        height: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $primary;
        margin: 1rem auto;
        img {
            width: 5rem;
            height: 5rem;
        }
    }
    &Perc {
        width: 8.5rem;
    }
}
