@import '../../styles/mixins';
@import '../../styles/variables';

.docList {
    margin: 2.5rem 0;
}
.docItem {
    padding: .6rem 0 .6rem 2.5rem;
    border-bottom: 1px solid $primary-light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url('../../images/icons/clipboard.svg') no-repeat left 8px;
    background-size: 2rem 2rem;

}
.docActions {
    flex-direction: row;
    display: flex;
    button {
        cursor: pointer;
        color: $primary;
    }
    li {
        margin-right: .5rem;
        svg {
           top: 3px;
            position: relative;
        }
    }
}