.course {
    background: #F6F6F6;
}

.header {
    display: flex;
    margin-right: 50px;
    justify-content: flex-end;
    li {
        flex: 0 0 54px;
        text-align: center;
        font-weight: bold;
    }
}
.levels {
    display: flex;
    margin-right: 10px;
    &Item {
        width: 54px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

