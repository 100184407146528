@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.progressHolder {
   width: 4rem;
   height: 4rem;
}

.caseForm {
    margin: auto;
    max-width: 47rem;
}

.member {
    display: flex;
    align-items: center;
    &Label {
        flex-basis: 5rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &Persona {
        flex-basis: 8rem;
        flex-shrink: 0;
    }
    &Name {
        flex-basis: 200px;
        margin-left: 1rem;
        text-align: left;
    }
    &Side {
        flex-basis: 18rem;
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: auto;
    }
    &SideStarted {
        flex-basis: 7rem;
    }
}
.memberMain {
    margin-bottom: 5px;
    padding: 8px 5px;
    background: lighten($primary, 20%);
}
.memberSupporting {
    padding: 0 .5rem .5rem 2rem;
    margin-bottom: 4px;
    border-bottom: 1px solid lighten($primary, 20%);
}
.caseTabs {
    justify-content: center;
}

.notesDocs {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 3rem;

}
.noteSep {
    padding-top: 1rem;
    border-top: 1px solid lighten($primary, 20%);
}

.chat {
    &Body {
        border-radius: 2rem;    
    }
    &Author {
        padding-top: .8rem;
        border-top: 1px solid lighten($primary, 20%);
    }
    &Img {
        img {
            border-radius: 10rem;
            display: block;
        }
    }
}

.chatEven {
    .chatImg {
        order: 1;
        margin-left: 1rem;
    }
}
.chatOdd {
    .chatImg {
        margin-right: 1rem;
    }
}

span.logLabel {
    min-width: 7.5rem;
    font-weight: bold;
}

.agreementStatus {
    flex: 0 0 12rem;
    display: flex;
    align-items: center;
}
.agreementLink,
.agreementLinkTop {
    margin-left: auto;
    text-align: right;
    display: flex;
    align-items: center;
}
.agreementLinkTop {
    padding: 5px 12px;
    border-radius: 1rem;
    border: 1px solid #B8A8AA;
}

.chatSelect {
    display: flex;
    align-items: center;
}

.chatHead {
    text-align: center;
    margin-right: .5rem;
    font-size: 1.2rem;
    &Holder {
        width: 4rem;
        border-radius: 9999px;
        overflow: hidden;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        box-shadow: 0 0.3rem .5rem 0 rgba(0, 0, 0, 0.2);
        &Active {
            border: 4px solid darken($primary, 15%);
        }
    }
    &Disabled {
        pointer-events: none;
        opacity: .5;
    }
    p {
        text-overflow: ellipsis;
        width: 6rem;
        margin-top: .5rem;
        white-space: nowrap;
        overflow: hidden;
    }
    &Img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.leader {
    &Photo,
    &NoPhoto {
        flex-basis: 4rem;
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;
        background-size: contain;
        background-position: center;
        border-radius: 50%;
    }

    &NoPhoto {
        background: rgb(126, 126, 126) url('../../../../images/icons/user.png') no-repeat center center;
    }
}

.statusIcon {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #B4B4B4;
    position: relative;
    &Reached {
        width: 15px;
        height: 15px;
        border-radius: 6px;
        background: $success;

        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 10px;
            color: #fff;
        }
        &Not {
            background: $danger;
        }

    }
    &Active {
        background: #666666;
    }
}