@import '../../styles/variables';

.row,
.header {
    display: flex;
    margin-bottom: 0.5rem;
    &Name {
        flex: 0 0 21rem;
        background: #F3CCBD;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: .5rem;
        color: $body-color;
    }
    &Stats {
        display: flex;
    }
}
.item,
.itemHeader {
    width: 85px;
    height: 66px;
    padding: 0.5rem;
    background: #666666;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: .5rem;
}
.itemCtc {
    margin-left: 1rem;
    background: #B4B4B4;
}

.headerName,
.itemHeader {
    background: none;
    height: auto;
    padding: .5rem 0;
    color: $body-color;
}