// Breakpoints
$mobile-landscape: 480px;	// Mobile-landscape (and larger)
$tablet-portrait: 768px;	// Tablet-portrait (and larger)
$tablet-landscape: 992px;	// Tablet-landscape (and larger)
$laptop: 1200px;	// Laptops (and langer)

.modal {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    &Blanker {
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        height: 100%;
        justify-content: center;
        overflow: auto;
        width: 100%;
    }
    &Content {
        background: white;
        bottom: 0;
        left: 0;
        overflow: auto;
        position: fixed;
        padding: 30px;
        right: 0;
        top: 0;
    }
    &CloseButton {
        align-items: center;
        background: red;
        border: none;
        border-radius: 15px;
        color: white;
        cursor: pointer;
        display: flex;
        font-size: 18px;
        height: 30px;
        justify-content: center;
        padding: 0;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 30px;
        &:hover {
           background: darken(red, 10%);
        }
    }
}

@media screen and (min-width: $mobile-landscape) {
    .modal {
        &Content {
            margin: 20px;
            max-height: calc(100vh - 40px);
            max-width: 500px;
            overflow: initial;
            position: relative;
            width: 100%;
            &Inner {
                max-height: calc(100vh - 100px);
                overflow: auto;
            }
            &Wide {
                max-width: 800px;
            }
        }
        &CloseButton {
            align-items: center;
            background: red;
            border: none;
            border-radius: 15px;
            color: white;
            cursor: pointer;
            display: flex;
            font-size: 18px;
            height: 30px;
            justify-content: center;
            padding: 0;
            position: absolute;
            right: -15px;
            top: -15px;
            width: 30px;
            &:hover {
               background: darken(red, 10%);
            }
        }
    }
}
