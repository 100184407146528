//Display
$display-properties: (
  none: none,
  flex: flex,
  block: block,
  inline: inline,
  inline-block: inline-block
)!default;

$width-properties: (
  full: 100%,
  auto: auto
)!default;

@each $point in map-keys($grid-breakpoints) {
  $infix: get-infix($point, $grid-breakpoints);
  @include breakpoint-up($point, $grid-breakpoints) {
    @each $name, $prop in $display-properties {
      .d#{$infix}-#{$name} {display: #{$prop}!important}
    }

    @each $name, $prop in $width-properties {
      .width#{$infix}-#{$name} {width: #{$prop}!important}
    }
  }
}

.relative {
  position: relative;
}

