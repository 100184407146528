@import '../../../../styles/variables';
@import '../../../../styles/mixins';


.case {
    text-align: center;
    color: $body-color;
    box-shadow: 0 0.2rem 0.5rem 0 rgba(243, 204, 189, 0.9);
    border-radius: 1rem;
    background: #fff;
    display: flex;
        flex: 1 0 0;
    &Archived {
        background: #EFEFEF;
    }
    &:hover h2 {
        color: $primary;
    }
    &Wrapper {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    &Header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        align-items: center;
        justify-content: center;
        position: relative;
        &Draft {
            position: absolute;
            left: 0;
        }
        &Mode {
            position: absolute;
            right: 0;
        }
    }
}