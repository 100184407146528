
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    min-height: 100%;
}

body {
    font-family: $body-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: left;
    font-weight: 300;
    height: 100%;
    color: $body-color;
    background-color: #F3E4DE;
    background-color: #fff;
    @include breakpoint-up(md) {
        font-size: $base-desktop-font-size;
    }
}
// #root {
//     min-height: 100%;
//         display: flex;
//         flex-direction: column;
// }
// .grow-children {
//     display: flex;
//         flex-direction: column;
//         flex-grow: 1;
// }
.loggedOutBody {
    background-color: #F3E4DE;
    min-height: 100vh;

}
.wrapper {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
}
.inner {
    transition: padding-left 200ms ease 0s;
    padding-top: 7rem;
    padding-bottom: 5rem;
    &Shrink {
        padding-left: 300px;
    }
}
.bodyWrapper {
   min-height: 100vh;
  // background: #D9DAD9;
}
