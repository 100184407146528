@import '../../styles/mixins';
@import '../../styles/variables';

.team {
    display: grid;
    grid-template-columns: 20rem 1fr;
    gap: 3rem;
    align-items: flex-start;
    position: relative;
    &Filters {
        position: sticky!important;
        top: 60px;
    }
}


.rjc,.dbs {
    border-radius: 1.5rem;
    background: #455660;
    color: #fff;
    padding: 1.4rem;
    min-height: 75px;
}
.dbs,
.experience {
    background: #666666;
}
.experience {
    color: lighten($primary, 20%);
    margin-bottom: 0.4rem;
    font-size: 1.8rem;
    svg {
        margin-right: 1rem;
        color:$primary
    }
}

.circleImgWrapper {
    flex-basis: 140px;
    flex-shrink: 0;
}


.circleImg {
    object-fit: cover;
    border-radius: 50%;
    height: 140px;
    width: 140px;
}

.levelBadge {
    color: #fff;
    padding: 1rem;
    text-align: center;
    background: #666666;
    &NoLevel {
        background: #C73C3C;
    }
}