//Spacing
$spacer: 1rem !default;
$spacers: (
  0: $spacer * 0,
  1: $spacer * 0.5,
  2: $spacer * 1.5,
  3: $spacer * 2.5,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6
)!default;

$space-properties: (
  margin: m,
  padding: p
);

//typography
$base-line-height:            1.6 !default;

$base-font-size:              1.6rem !default;
$lead-font-size:              1.6rem !default;

$base-desktop-font-size:      1.6rem !default;
$lead-desktop-font-size:      1.8rem !default;

$small-font-size:             1.2rem !default;

$body-font-family:            "Roboto", sans-serif !default;
$headings-margin-bottom:      $spacer !default;
$headings-font-family:        "Roboto", sans-serif !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;

$h1-font-size:                $base-font-size * 1.6 !default;
$h2-font-size:                $base-font-size * 1.4 !default;
$h3-font-size:                $base-font-size * 1.2 !default;

$h1-desktop-font-size:        $base-desktop-font-size * 1.5 !default;
$h2-desktop-font-size:        $base-desktop-font-size * 1.25 !default;
$h3-desktop-font-size:        $base-desktop-font-size * 1.125 !default;

//Colors
$body-color   : #666666;
$primary      : #E89B89;
$primary-light: #F3E4DE;
$danger       : red;
$blue         : #6AABC9;
$success      : #97BD15;
$warning      : #D8A027;

$gray-1:     #D9D9D9 !default;
$gray-2:     #C6CBB5 !default;
$gray-3:     #888888 !default;
$gray-muted: #B4B4B4 !default;

$theme-colors: (
  "dark"          : $body-color,
  "white"         : #fff,
  "primary"       : $primary,
  "primary-light" : $primary-light,
  "danger"        : $danger,
  "muted"         : $gray-3,
  "muted-2"       : #86929D,
  "blue"          : $blue,
  'gray'          : lighten($gray-1, 10%),
  'gray-1'        : $gray-1,
  'gray-2'        : $gray-2,
  'success'       : $success,
  'warning'       : $warning,
  'gray-muted'    :$gray-muted
);


$level-colors:(
  "red":  #C73C3C,
  "amber": #D8A027,
  "green": #8BC267,
  "blue": #8FC5DE,
  "grey": #D8D8D8,
  "grey-2": #B4B4B4
)