.introPanel {
    max-width: 50rem;
    margin: auto;
    margin-top: 5rem;
    text-align: center;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 1rem;
    padding: 3rem;
    box-shadow: 0 0 4px rgba(0,0, 0,0.3);
    .logo {
        margin: auto;
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .form {
        max-width: 30rem;
        margin: auto;
    }
}