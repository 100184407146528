.animate-spin {
    display: block;
    animation: spin 1s linear infinite;
}



@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.opacity-25 {
    opacity: 0.25;
}

.opacity-75 {
    opacity: 0.75;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}