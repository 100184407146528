@import '../../styles/variables.scss';

.tab {
    cursor: pointer;
    margin: 0 .5rem;
    color: #fff;
    background: #B4B4B4;
    padding: .2rem 1.4rem;
    border-radius: 2rem;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
}
.disabled {
    cursor: default;
    pointer-events: none;
}
.selected {
    background: $primary;
}

