@import '../../styles/mixins';
@import '../../styles/variables';
.topbar {
    background: #fff;
    height: 5rem;
    padding-left: 6rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
        box-shadow: rgba(0, 0, 0, 0.08) 0 2px 3px ;
    &Content {
        height: 5rem;
        display: flex;
        align-items: center;
        &Expanded {
            margin-left: 300px;
        }
    }
}
.sidebar {
    background: #fff;
    width: 30rem;
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.08) 2px 0px 2px 0px;
    top: 0;
    left: 0;
    align-items: stretch;
    transition: left 200ms ease 0s;
    overflow: visible;
    z-index: 99;
    display: flex;
    visibility: visible;
    flex-direction: column;
    height: 100%;
    bottom: 0;

    &ToggleIcon {
        position: absolute;
        right: -40px;
        background: #fff;
        width: 4rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: right;
        border-right: 1px solid #e3e3e3;
        box-shadow: rgba(0, 0, 0, 0.08) 1px 0px 2px 0px;
    }
    &HoverIcon {
        position: absolute;
        left: 10px;
        background: #fff;
        width: 3rem;
        height: 5rem;
        text-align: right;
        align-items: center;
        display: flex;
    }
    &Collapsed {
        left: -300px;
    }
    &Hovered {
        left: 0;
        position: fixed;
    }
    &Main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 2rem;
        overflow-y: auto;
        &NavLink {
            color:#979797;
            display: block;
            margin-bottom: .8rem;
            padding-bottom: .8rem;
            border-bottom: 1px solid #979797;
            &Active {
                color: $blue;
               border-color: $blue;
            }
        }
        &OrgLogo {
            width: 30px;
            height: 30px;
            margin-right: 1rem;
        }
    }
    &Footer {
        margin-top: auto;
        &Link {
            margin-bottom: .5rem;
            img, svg {
                width: 30px;
                height: 30px;
                margin-right: 1rem;
            }
            svg {
                color: #B8A8AA;
            }
        }
    }
}
.borderedLink {
    border: 1px solid lighten($primary, 20%);
    padding: .6rem 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    color: $body-color;
}


.nestedOffices {
    padding-left: 1rem;
    border-left: 2px solid $blue;
}