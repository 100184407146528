@each $color, $value in $theme-colors {
    .bg-#{$color} {
        background-color: $value !important
    }
}

@each $color, $value in $level-colors {
    .l-#{$color} {
        background-color: $value !important
    }
}