@import '../../styles/mixins';
@import '../../styles/variables';

.settings {
    display: grid;
    grid-template-columns: 20rem 1fr;
    gap: 3rem;
    align-items: flex-start;
}
.nav {
    li {
        cursor: pointer;
        &:hover {
            color: $blue
        }
    }
}