.dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    &Btn {
      padding: 0;
      border: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      background: none;
      svg {
        color: #afb697;
      }
      &:focus {
        outline: none;
      }
      &TitleLeft {
        margin-right: 5px;
      }
      &TitleRight {
        margin-left: 5px;
      }
      &IsActive svg {
        color: #9dc030;
      }
    }
  
    &Menu {
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
      z-index: 10;
      position: absolute;
      top: 100;
      right: 0;
      min-width: 160px;
      max-width: 240px;
      white-space: nowrap;
      list-style: none;
      padding: 0;
      margin: 0;
            &Expand {
                max-width: none;
              }
      li {
        padding: 5px 10px;
        margin: 0;
        &:not(:last-child) {
          border-bottom: 1px solid #e6e6e6;
        }
      }
      a {
        color: #3a3a3a;
        &:hover:not(:disabled) {
          color: #9dc030;
        }
      }
      button {
        border: 0;
        padding: 0;
        background: none;
        cursor: pointer;
        color: #3a3a3a;
        display: block;
        width: 100%;
        text-align: left;
        &:hover:not(:disabled) {
          color: #9dc030;
        }
        &:focus {
          outline: none;
        }
        &:disabled {
          opacity: 0.7;
          cursor: default;
        }
      }
      &Left {
        right: auto;
        left: 0;
      }

    }
  }
  