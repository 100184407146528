@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

a {
    color: $primary;
    text-decoration: none;
}
.u-link {
    cursor: pointer;
}
.u-underline {
    text-decoration: underline;
}

//typography
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  line-height: $headings-line-height;
  color: $headings-color;
  font-weight: 300;
}

h1, .h1 {
  font-size: $h1-font-size;
}
h2, .h2 {
  font-size: $h2-font-size;
}
h3, .h3, h4, h5, h6 {
  font-size: $h3-font-size;
}

@include breakpoint-up(md) {
  h1, .h1 {
    font-size: $h1-desktop-font-size;
  }
  h2, .h2 {
    font-size: $h2-desktop-font-size;
  }
  h3, .h3, h4, h5, h6 {
    font-size: $h3-desktop-font-size;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.bold {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.light {
  font-weight: 300;
}
.body {
  font-size: $base-font-size;

  @include breakpoint-up(md) {
    font-size: $base-desktop-font-size;
  }
}
.lead {
    font-size: 1.8rem;
}
.small {
  font-size: 1.2rem;
}
