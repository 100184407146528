$radius: 1rem !default;
$radiuses: (
    0: $spacer * 0,
    1: $spacer * 0.5,
    2: $spacer * 1.5,
    3: $spacer * 2.5,
    4: $spacer * 4) !default;



@each $name, $prop in $radiuses {
    .radius-#{$name} {
        border-radius: #{$prop} !important
    }
}