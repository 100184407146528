@import '../../styles/mixins';
@import '../../styles/variables';

.iconBtn {
    height: 2.4rem;
    width: 2.4rem;
    background: $primary;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.2rem;
}