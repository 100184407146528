@each $point in map-keys($grid-breakpoints) {
  $infix: get-infix($point, $grid-breakpoints);
  @include breakpoint-up($point, $grid-breakpoints) {
    .text#{$infix}-left { text-align: left!important; }
    .text#{$infix}-right { text-align: right!important; }
    .text#{$infix}-center { text-align: center!important; }
  }
}

@each $color, $value in $theme-colors {
  .text-#{$color} {color: $value!important};
}

@each $color, $value in $theme-colors {
  .text-#{$color} {color: $value!important};
}

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background: $value !important
  }
}

.bold {
  font-weight: bold;
}
.small {
  font-size: 1.2rem;
}
.lead {
  font-size: 1.7rem;
}

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.underline {
  border-bottom: 1px solid #979797;
  padding-bottom: 10px;
}

.newlines {
  white-space: pre-wrap;
}