.reports {
    display: grid;
    grid-template-columns: 20rem 1fr;
    gap: 3rem;
    align-items: flex-start;
}

.session {
    display: flex;
    align-items: center;
    &Name,
    &Circle {
        flex-basis: 200px;
        flex-grow: 1;
        max-width: 280px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 1rem;
    }
    &Date {
        flex-basis: 180px;
    }
    &Status {
        flex-basis: 100px;
        margin-left: auto;
        text-align: center;
        span {
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            color: #fff;
            border-radius: 50%;
        }
    }
}
.filterMenu {
    width: 250px;
    background: #fff;
    border-radius: 1rem;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 2rem;
    max-height: 500px;
    overflow: auto;
    z-index: 2;
        box-shadow: 0 0.2rem .5rem 0 rgba(243, 204, 189, 0.9);
}

.radioSelect {
    width: 24px;
    height: 24px;
    box-shadow: inset 0px 0px 0px 2px #333;
    background: #fff;
    border-radius: 50%;
    margin-right: 1rem;
    position: relative;
    &Active {
        box-shadow: inset 0px 0px 0px 2px white,  0px 0px 0px 2px #666666;
        background: #666666;
        width: 20px;
        height: 20px;
        left: 2px;
        margin-right: 14px;
    }
}
.customCal {
    margin-left: 35px;
}