.list-group {
    .list-item {
        padding: .5rem 0;
        border-bottom: 1px solid lighten($gray-1, 5%);
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &--no-border {
            border-bottom: none;
        }
        &:last-child {
            border-bottom: none;
        }
        &--disabled {
            opacity: 0.6;
            cursor: default;
            pointer-events: none;
        }
        &--separator {
            border-bottom: 1px solid $primary;
        }

        &--separator-dark {
            border-bottom: 1px solid #979797;
            &:last-child {
                border-bottom: 1px solid #979797;
            }
        }
        &--link {
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
        &--full {
            flex: 0 0 100%;
        }
    }
}
.separator {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background: #F3CCBD;
    height: 1px;
    border: none;
}
.legendWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}
.legendItem {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin: 0 1rem 1rem 0;
    flex-basis: 30%;
    span {
        display: inline-block;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
    }
}
.sideNavLink {
    display: block;
    width: 100%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #B8A8AA;
    text-align: left;

    &:disabled {
        opacity: .5;
        pointer-events: none;
    }

    &Active {
        border-color: $primary;
        border-width: 2px;
    }
}